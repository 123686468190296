#wrapper {
  display: flex;
  height: auto;
  min-height: 84vh;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  #home-container {
    width: 60%;
    background-color: #f9f9f9;
    padding-bottom: 1px;
    border: 2px solid #cfc5fb;
    border-radius: 2px;
    h1 {
      color: #fc466b;
      text-align: center;
      padding-top: 50px;
    }
    p {
      margin-left: 30px;
      margin-right: 30px;
    }
    span {
      font-weight: bold;
    }
    #list-button {
      transition: 0.5s;
      padding: 15px 20px;
      margin: 2rem auto;
      text-align: center;
      width: 200px;
      font-size: 18px;
      color: #fff!important;  
      background-color: #3ecd84 !important;
      height: auto !important;
      min-height: 36px;
      box-sizing: border-box;
      line-height: 1.4em;
      font-weight: 600!important;
      border-radius: 5px;
      white-space: nowrap;
      border: none !important;
    }
    a:link {
      text-decoration: none;
    }
    #list-button:hover {
      background-color: #130D38;
    }
  }
  #already-listed-text {
    margin: 0 auto;
    width: 60%;
    //text-align: center;
  }
  #margin-button {
    margin-left: 20px;
  }
}
