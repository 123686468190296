#main-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .help a {
    position: relative;
    color: black;
  }
  .help a span {
    position: absolute;
    display: none;
    z-index: 99;
  }
  .help a:hover span {
    display: block;
  }
  h1 {
    margin: 0 auto;
    text-align: center;
    margin-top: 32px;
    margin-bottom: 10px;
    width: 70%;
    font-size: 24px;
  }
  h3 {
    margin: 2px auto 10px;
    color: red;
    font-size: 19px;
  }
  p {
    margin: 0 auto;
    width: 60%;
    text-align: center;
    font-size: 16px;
  }
}
