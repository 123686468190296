.bulkInsertInput {
  border-radius: 7px;
  padding: 8px;
  margin-top: 10px;
}

.bulk-insert-btn, .bulk-insert-btn:hover, .bulk-insert-btn:focus {
  border-radius: 3px;
  margin-top: 10px;
  margin-right: 10px;
  padding: 4px 10px;
  font-size: 1rem;
  line-height: 1rem;
  color: #fff !important;
  background-color: #3ecd84;
  height: auto !important;
  box-sizing: border-box;
  line-height: 1.4em;
  font-weight: 600 !important;
  border-radius: 5px;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  border: none !important;
  cursor: pointer;
}

.bulk-insert-btn::after{display:none;}
