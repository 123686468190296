.addWhatsMissingButton {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.addWhatsMissing {
  margin-left: 5px;
  font-weight: 400;
  font-size: 14px;
  color: #403488;
}

.addMissingTaxonomyInputContainer {
  border: 1px solid lightgrey;
  width: 389px;
  height: 32px;
  display: flex;
  align-items: center;
  border-radius: 2px;
  padding-right: 5px;
}

.addMissingTaxonomyInput {
  margin: 0px !important;
  border: 0px !important;
  width: 100%;
}

.addMissingTaxonomyInput:focus {
  outline: none;
  box-shadow: none !important;
}

.addMissingTaxonomyButton {
  cursor: pointer;
  height: 32px;
  border: none;
  width: 84px;
  background-color: lightgrey;
  color: white;
  margin-left: 10px;
  border-radius: 2px;
  font-weight: 500;
}

.addMissingTaxonomyButtonActive {
  background-color: #21a160;
}

.deleteMissingTaxonomyButton {
  cursor: pointer;
  height: 32px;
  border: none;
  width: 84px;
  background-color: white;
  border: 1px solid #ff7972;
  color: #ff7972;
  margin-left: 10px;
  border-radius: 2px;
}

.checkbox-group {
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  display: grid;
}

.checkbox-group-single-col {
  grid-template-columns: 1fr;
  display: grid;
}

.checkbox-group {
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  display: grid;
}

.checkbox-group-single-col {
  grid-template-columns: 1fr;
  display: grid;
}