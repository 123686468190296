* {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
#main-container {
  background-color: #f1f1fa;
  p {
    margin-bottom: 30px;
  }
  .errMsg {
    margin-left: 5px;
    color: red;
    font-size: 0.75rem;
  }
  .NA {
    font-weight: 700;
    transition: 0.1s;
    cursor: pointer;
    &:hover {
      text-shadow: 0px 0px 1px #ff2727;
    }
  }
}
.ant-steps {
  width: 90vw !important;
  margin: auto !important;
  color: #fc466b !important;

  .ant-steps-item-description {
    font-size: 0.75rem !important;
  }

  .ant-steps-item-active {
    color: red !important;
  }

  .ant-steps-item-icon {
    background-color: #ffffff !important;
    border-color: #000000 !important;
    cursor: pointer;
    .ant-steps-icon {
      //font-weight: bold !important;
      color: rgb(15, 15, 15) !important;
      cursor: pointer;
      height: 4px;
    }
  }
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    transition: 0.7s;
    background-color: #3ece84 !important;
    height: 4px;
  }
  @media only screen and (max-width: 600px) {
    display: none !important;
  }
}
#steps {
  .small-steps {
    display: none;
    justify-content: space-evenly;
    .steps-header-mobile {
      font-size: 1.25rem;
    }
    @media only screen and (max-width: 600px) {
      display: flex;
    }
  }
}

#form-container .sticky {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  border-bottom: 1px solid lightgray;
  border-radius: 0px;
}

#form-container {
  width: 100%;
  height: auto;
  scroll-behavior: smooth;
  .notSticky {
    -webkit-transition: background-color 1s ease;
    transition: background-color 1s ease;
    background-color: #f1f1fa;
    padding: 15px 20px !important;
  }
  .sticky {
    position: fixed;
    top: 0;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 100%;
    z-index: 999;
    -webkit-transition: background-color 1s ease;
    transition: background-color 1s ease;
    background-color: #fff;

    .ant-steps {
      .ant-steps-item-icon {
        background-color: #f1f1fa !important;
        .ant-steps-icon {
          font-weight: bold !important;
          color: rgb(131, 130, 130) !important;
        }
      }
    }
  }
  h1 {
    margin-bottom: 40px;
  }
  .description-text {
    font-size: 0.75rem;
    opacity: 0.45;
    margin: 10px 0px;
  }
  .ifSticky {
    padding-top: 104px;
  }

  .wrapper-shadow {
    box-shadow: inset 0 -10px 18px -10px #f1f1fa;
  }
  .checkbox-wrapper {
    overflow-y: scroll;
    max-height: 360px;
    margin: 15px 0px;
    padding: 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    .bonus-inputs {
      width: 25%;
      min-width: 200px;
      border-radius: 5px;
      border: 1px solid #dcdbeb;
      margin-bottom: 20px;
      padding-left: 7px;
      line-height: 32px;
      margin-top: 6px;
      &:focus {
        transition: 0.1s;
        outline: none;
        border: 1px solid #cfc5fb;
        box-shadow: rgba(72, 72, 72, 0.1) 0px 0px 5px 3px;
      }
    }

    .affiliateTypeCheckbox {
      width: 200px;
      display: block;
      .checkmark {
        top: 3px !important;
      }
    }
    .affiliate-textarea {
      border-radius: 5px;
      border: 1px solid #dcdbeb;
      padding: 7px;
      width: 50%;
      height: 70%;
      min-width: 200px;
      &:focus {
        transition: 0.1s;
        outline: none;
        border: 1px solid #cfc5fb;
        box-shadow: rgba(72, 72, 72, 0.1) 0px 0px 5px 3px;
      }
    }
    .main-input {
      padding-left: 7px;
      width: 50%;
      min-width: 200px;
      margin-bottom: 10px;
      border-radius: 5px;
      border: 1px solid #dcdbeb;
      line-height: 32px;
      margin-top: 6px;
      &:focus {
        transition: 0.1s;
        outline: none;
        border: 1px solid #cfc5fb;
        box-shadow: rgba(72, 72, 72, 0.1) 0px 0px 5px 3px;
      }
    }
    .other-input {
      padding-left: 7px;
      width: 50%;
      min-width: 200px;
      border-radius: 5px;
      border: 1px solid #dcdbeb;
      &:focus {
        transition: 0.1s;
        outline: none;
        border: 1px solid #cfc5fb;
        box-shadow: rgba(72, 72, 72, 0.1) 0px 0px 5px 3px;
      }
    }
  }
  #contact {
    height: auto;
  }
  .first-step {
    height: auto;
  }
  #licenses {
    height: 200px;
  }
  #supports {
    height: 200px;
  }
  .brand-information {
    height: auto;
  }
  #bonuses {
    height: auto;
  }
  #contact-info {
    height: auto;
  }
  #signuplink {
    height: 150px;
    div {
      padding-left: 7px;
      input {
        width: 100%;
      }
    }
  }
  #usp {
    height: 200px;
  }
  .sub-container {
    padding: 2rem;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
    // display: flex;
    // justify-content: space-between;
    box-shadow: rgba(72, 72, 72, 0.1) 0px 0px 5px 3px;
    color: rgb(51, 51, 51);
    border: none;
    border-radius: 6px;
    width: 100%;
    height: auto;
    @media only screen and (min-width: 1024px) {
      width: auto;
      max-width: 60%;
    }
    @media only screen and (max-width: 600px) {
      border-radius: 0px;
    }

    .search-container {
      width: 25%;
      min-width: 200px;
      border-radius: 5px;
      border: 1px solid #dcdbeb;
      margin: 15px 5px 0px 0px;
      padding-left: 30px;
      position: relative;
      .search-icon {
        position: absolute;
        left: 7px;
        top: 5px;
      }
      .sub-con-input {
        border: none;

        &:focus {
          outline: none;
        }
        &:focus ~ .search-icon {
          transition: 0.25s;
          color: #fc466b;
        }
      }
    }
    .highlight-searchfield {
      transition: 0.1s;
      outline: none;
      border: 1px solid #cfc5fb;
      box-shadow: rgba(72, 72, 72, 0.1) 0px 0px 5px 3px;
    }
    .help {
      margin-left: 10px;
    }
    .help a {
      position: relative;
    }
    .help a span {
      position: absolute;
      display: none;
      z-index: 99;
    }
    .help a span img {
      box-shadow: rgba(72, 72, 72, 0.1) 0px 0px 4px 1px;
      border-radius: 5px;
      max-width: 700px;
      max-height: 500px;
      padding: 5px;
      background-color: #fff;
    }
    .help a:hover span {
      display: block;
      max-height: 150px;
    }
    .addTaxonomyWrapper {
      margin-top: 5px;
      display: block;
      width: 33%;
      input {
        border-radius: 5px;
        border: 1px solid #dcdbeb;
        margin: 15px 5px 0px 0px;
        padding-left: 7px;
        &:focus {
          transition: 0.1s;
          outline: none;
          border: 1px solid #cfc5fb;
          box-shadow: rgba(72, 72, 72, 0.1) 0px 0px 5px 3px;
        }
      }
    }
    .addTaxBtn {
      color: #3ecd84;
      font-size: 28px;
      position: relative;
      top: 8px;
      &:hover {
        cursor: pointer;
      }
    }
    h3 {
      font-weight: 700;
      font-size: 25px;
    }
    .preview-container {
      margin-bottom: 10px;
      #preview {
        height: 25%;
        margin: 0 auto;
      }
      input {
        display: none;
      }
      .upload-file {
        margin-top: 10px;
        padding: 4px 10px;
        font-size: 1rem;
        line-height: 1rem;
        color: #fff !important;
        background-color: #3ecd84;
        height: auto !important;
        box-sizing: border-box;
        line-height: 1.4em;
        font-weight: 600 !important;
        border-radius: 5px;
        text-transform: none;
        display: inline-block;
        white-space: nowrap;
        border: none !important;
        cursor: pointer;
      }
    }
    .checkbox-container {
      width: 300px;
      margin-bottom: 5px;

      .addNewValueButton {
        font-size: 2rem;
        color: rgb(120, 87, 255);
        margin-bottom: -500px;
        cursor: pointer;
      }

      .data {
        white-space: nowrap;
        //overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .preview-application {
      span {
        margin-bottom: 5px;
      }
      h4 {
        font-size: 1rem;
      }

      hr {
        border: 1px solid #f1f1fa;
        margin-bottom: 10px;
      }
    }
    .center {
      margin: 0 auto;
    }
  }

  .nav-container {
    @media only screen and (max-width: 600px) {
      height: 50px;
      padding: 0.6rem;
      position: sticky;
      bottom: 0;
    }

    .button-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      line-height: 1.4em;
      .nav-arrow {
        font-size: 1.35rem;
        margin-bottom: -4px;
      }
      .nav-button {
        transition: 0.5s;
        padding: 15px 20px;
        min-width: 112px;
        font-size: 18px;
        color: #fff !important;
        background-color: #3ecd84 !important;
        height: auto !important;
        min-height: 36px;
        box-sizing: border-box;
        line-height: 18px;
        font-weight: 600 !important;
        border-radius: 5px;
        white-space: nowrap;
        border: none !important;
        @media only screen and (max-width: 600px) {
          padding: 7px 10px;
        }
        &:disabled {
          background-color: red !important;
          opacity: 0.5 !important;
        }
      }
    }
  }
  .affiliateTypeCheckbox {
    width: 200px;
    display: block;
    align-items: center;
    .checkmark {
      top: 3px !important;
    }
  }
  .data {
    position: relative;
    // transition: 0.05s;
    font-size: 1rem;
    cursor: pointer;
    &:hover {
      color: #fc466b;
    }
    input {
      margin-left: 15px;
      opacity: 0;
      cursor: pointer;
      &:checked ~ .checkmark:after {
        display: block;
      }
      &:checked ~ .checkmark {
        background-color: #3ecd84;
        // transition: 0.1s;
      }
      &:checked ~ .radio {
        border: 1px solid #3ecd84;
        // transition: 0.1s;

        .selected {
          background-color: #3ecd84;
        }
      }
    }
    .checkmark {
      position: absolute;
      top: 0px;
      left: 0;
      height: 1rem;
      width: 1rem;
      background-color: #eff3fc;
      border-radius: 2px;
      &:after {
        content: "";
        position: absolute;
        display: none;
      }
      &:after {
        left: 0.35rem;
        top: 0.2rem;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    .radio {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 3px;
      left: 0;
      height: 1rem;
      width: 1rem;
      border: 1px solid lightgray;
      border-radius: 1rem;
      &:after {
        content: "";
        position: absolute;
        display: none;
      }
      &:after {
        left: 0.35rem;
        top: 0.2rem;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      .selected {
        width: 8px;
        height: 8px;
        border-radius: 8px;
      }
    }
  }

  #bonus-currency-input {
    margin-left: 10px;
    width: 80px;
    min-width: 80px;
  }

  .checked {
    transition: 0.1s;
    color: #fc466b;
    font-weight: 700;
  }
  .select-all-button {
    margin-top: 10px;
    padding: 4px 10px;
    font-size: 1rem;
    line-height: 1rem;
    color: #fff !important;
    background-color: #3ecd84;
    height: auto !important;
    box-sizing: border-box;
    line-height: 1.4em;
    font-weight: 600 !important;
    border-radius: 5px;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    border: none !important;
    cursor: pointer;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

.extra-wrapper {
  margin-top: 60px;
}

.steps-logo.hidden {
  display: none;
}

.background-container {
  .bg-color-selector {
    input {
      display: block;
      box-sizing: border-box;
      width: 200px;
      padding: 6px;
      border: 1px solid #ddd;
      border-radius: 4px;
      background: #fff;
      outline: none;
      font: inherit;
      text-transform: uppercase;
      text-align: center;
      margin: 10px 0px;
    }
  }

  .choose-color {
    margin-top: 10px;
    padding: 4px 10px;
    font-size: 1rem;
    line-height: 1rem;
    color: #fff !important;
    background-color: #3ecd84;
    height: auto !important;
    box-sizing: border-box;
    line-height: 1.4em;
    font-weight: 600 !important;
    border-radius: 5px;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    border: none !important;
    cursor: pointer;
  }
}

@media only screen and (min-width: 600px) {
}
