footer {
  height: 8vh;
  width: 100%;
  background: #130D38;
  position: absolute;
  bottom: 0px;
  .foot-container {
    position: relative;
    .foot-content {
      position: absolute;
      right: 20px;
      padding-top: 10px;
      img {
        width: 200px;
      }
      span {
        width: 100%;
        color: white;
      }
    }
  }
}
