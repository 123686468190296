@import '../../assets/styles/variables.scss';

.mainPage {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $antiFlashWhite;
  height: 85vh;
    .success {
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 24px 24px 32px;
      background-color: $white;
      border-radius: 8px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
      .imageWrapper {
        display: flex;
        justify-content: center;
      }
      .textContainer {
        display: flex;
        flex-direction: column;
        .align {
          text-align: center;
        }
        .green {
          color: $greenCrayola;
          font-size: 30px;
          font-weight: 500;
          line-height: 40px;
          margin-top: 24px;
          margin-bottom: 8px;
        }
        .mainText {
          font-size: 16px;
        }
        .btn {
          margin-top: 24px;
          padding: 8px 16px;
          background-color: $darkSlateBlue;
          color: $white;
          border-radius: 4px;
          line-height: 22px;
          font-weight: 500;
          cursor: pointer;
        }
      }
      img {
        width: 140px;
        height: 140px;
      }
    }
}
