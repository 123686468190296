header {
  height: 72px;
  position: relative;
  -webkit-box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.1);
}
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  -webkit-box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.1);
}

.sticky + .content {
  padding-top: 102px;
}

header img {
  width: 200px;
  position: absolute;
  left: 32px;
  top: 50%;
  transform: translate(0, -50%);
}
