
.step-wrapper {
   position: relative;
   display: flex;
   flex-direction: column;
   align-items: center;
   width: 200px;
   cursor: pointer;

   .circle {
    height: 34px;
    width: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 34px;
    margin-bottom: 10px;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.45);
  }

  .activeCircle{
    background-color: #403488;
    border: 1px solid #403488;
    color: white;
  }

  .filledCircle{
    background-color: white;
    border: 1px solid #403488;
  }

  .emptyCircle{
    background-color: white;
    border: 1px solid red;
  }

  .content{
    text-align: center;
    font-size: 16px;
    color: grey;
  }

  .activeContent{
    color: black;
  }

  .tail{
      width: 40%;
      height: 1px;
      border-radius: 2px;
      background-color: #403488;
      position: absolute;
      top: 15px;
      left: 0px;
      margin-left: 80%;
  }
}






